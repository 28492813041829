import { SHOP_CREDIT_TYPE, SHOP_TYPE } from '@/type/shop'

export const creditTypes = [
	{
		type: SHOP_CREDIT_TYPE.DAILY,
		text: 'รายวัน'
	},
	{
		type: SHOP_CREDIT_TYPE.HALF_MONTH,
		text: 'รายครึ่งเดือน'
	},
	{
		type: SHOP_CREDIT_TYPE.MONTHLY,
		text: 'รายเดือน'
	},
	{
		type: SHOP_CREDIT_TYPE.TOP_UP,
		text: 'เติมเงิน'
	},
	{
		type: SHOP_CREDIT_TYPE.WEEKLY,
		text: 'รายสัปดาห์'
	},
	{
		type: SHOP_CREDIT_TYPE.CHANNEL,
		text: 'สื่อกลาง',
	}
]

export const mapCreditType = (creditType: SHOP_CREDIT_TYPE) => {
	return creditTypes.find(each => each.type === creditType)
}

export const mapShopType = (shopType: SHOP_TYPE) => {
	if (shopType === SHOP_TYPE.CHANNEL) {
		return {
			color: 'success',
			text: 'โปรโมชั่นค่าส่งจากขนส่งเดิมที่มีอยู่แล้ว',
			value: SHOP_TYPE.CHANNEL
		}
	}
	if (shopType === SHOP_TYPE.DEALER) {
		return {
			color: 'warning',
			text: 'โปรโมชั่นร้านรับส่งพัสดุ',
			value: SHOP_TYPE.DEALER
		}
	}

	return {
		color: 'primary',
		text: 'โปรโมชั่นค่าขนส่งสุดพิเศษจาก Order Plus',
		value: SHOP_TYPE.SELLER
	}
}

export const shopTypeList = [
	mapShopType(SHOP_TYPE.SELLER),
	mapShopType(SHOP_TYPE.CHANNEL),
	mapShopType(SHOP_TYPE.DEALER),
]