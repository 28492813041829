
import { computed, defineComponent, PropType, ref } from 'vue'
import { ElNotification } from 'element-plus'

export default defineComponent({
	name: 'CustomUpload',
	props: {
		title: {
			type: String,
			default: ''
		},
		imageUrl: {
			type: [String, null] as PropType<string | null>,
			default: null
		},
		imageFile: {
			type: null,
			default: null
		},
		accept: {
			type: String,
			default: 'image/*'
		},
	},
	setup(props, { emit }) {
		const uploadImagePreview = ref()
		const onUploadComplete = (upload: any) => {
			emit('update:imageUrl', URL.createObjectURL(upload.file))
			emit('update:imageFile', upload)
		}

		const validateBeforeUpload = (file: any) => {
			//
			const isValidType = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLimit10mb = file.size / 1024 / 1024 < 10

			if (!isValidType) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'รูปแบบไฟล์ไม่ถูกต้อง',
					type: 'error'
				})
			}
			if (!isLimit10mb) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'ขนาดรูปภาพต้องไม่เกิน 10mb',
					type: 'error'
				})				
			}
			return isValidType && isLimit10mb
		}

		const onClickImagePreview = (event: any) => {
			event.stopPropagation()
			uploadImagePreview.value.clickHandler()
		}

		const isUpload = computed(() => {
			return props.imageUrl ? true : false
		})

		const onRemoveImg = () => {
			emit('update:imageUrl', '')
			emit('update:imageFile', null)
		}

		return {
			uploadImagePreview,
			isUpload,
			onClickImagePreview,
			onUploadComplete,
			validateBeforeUpload,
			onRemoveImg,
		}
	}
})
