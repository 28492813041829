
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { formatDefault } from '@/utils/dayjs'
export default defineComponent({
	name: 'ShopBank',
	setup() {
		const store = useStore()
		const shopDetail = computed(() => store.state.shop.shopDetail)
		return {
			shopDetail,
			formatDefault
		}
	}
})
