
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { getSearchUser, generateInviteUserLink,getUserRoles, getUserInShop, inviteUserToShop, revokeUserFromShop } from '@/api/shop/shop.api'
import { useStore } from '@/store'
import { Delete } from '@element-plus/icons'
import { rolesMap, Roles } from '@/constants/roles'
import { ElMessageBox, ElNotification } from 'element-plus'
import { useRoute } from 'vue-router'
import { baseCore } from '@/config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'


interface UserSearchI {
	uid: string;
	displayName: string;
}

export default defineComponent({
	name: 'ShopUser',
	components: { Delete },
	setup () {
		const store = useStore()
		const route = useRoute()
		const search = ref('')
		const userAddList = ref<UserSearchI[]>([])
		const roles = ref([])
		const userInShopList = ref([])
		const isShowDialogInviteUser = ref(true)
		const isGenerateLink = ref(false)
		const inviteLink = ref('')
		const submitData = reactive({
			// userId: null,
			isSubmitting: false,
			roleId: '',
			isSystemAdmin: false
		})

		const onCopyLink = () => {
			toClipboard(inviteLink.value)

			ElNotification({
				title: 'สำเร็จ',
				message: 'ก็อปปี้ลิ้งเชิญสำเร็จ',
				type: 'success'
			})
		}

		const handleSearchEmployee = (queryString: string, cb: any) => {
			if (!queryString) return cb([])
			getSearchUser(search.value)
				.then(({ data }) => {
					cb(data)
				})
		}
		const handleSelectEmployee = (user: UserSearchI) => {
			const arrayId = userAddList.value.map(user => user.uid)
			// Check Already Exist Id
			if (arrayId.includes(user.uid)) return
			userAddList.value.push(user)
		}
		const onRemoveUser = (uid: string) => {
			userAddList.value = userAddList.value.filter(user => user.uid !== uid)
		}
		const onDeleteUserFromList = (userId: string) => {
			//
			ElMessageBox.confirm(
				'ยืนยันลบผู้ใช้งาน?',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'error',
				}
			)
				.then(async () => {
					// Delete Then Fetch again
					await revokeUserFromShop({ shopId: route.params.shopId as string, userId })
					ElNotification({
						title: 'สำเร็จ',
						message: 'ลบผู้ใช้งานออกจากร้านแล้ว',
						type: 'success'
					})
					await fetchUserInShopList()
				})
		}
		const submitInvite = async () => {
			submitData.isSubmitting = true
			try {
				const submitPromiseArray = userAddList.value.map(user => {
					const payload = {
						shopId: route.params.shopId as string,
						userId: user.uid,
						roleId: submitData.roleId as any
					}
					return inviteUserToShop(payload)
				})
				
				await Promise.all(submitPromiseArray)
				await fetchUserInShopList()
				ElNotification({
					title: 'สำเร็จ',
					message: 'เพิ่มผู้ดูแลเข้าสู่ร้าน',
					type: 'success'
				})
			} catch (error: any) {
				console.log(error.response)
				ElNotification({
					title: 'ไม่สำเร็จ',
					message: error.response?.data?.resDesc === 'USER_IS_EXISTING' ? 'ผู้ใช้งานอยู่ในร้านนี้แล้ว': error.response?.data?.resDesc,
					type: 'error'
				})
			} finally {
				userAddList.value = [] // clear user list
				submitData.isSubmitting = false
			}
		}
		const fetchUserInShopList = async () => {
			try {
				const { data } = await getUserInShop(route.params.shopId as string)
				userInShopList.value = data
			} catch (error) {
				console.log('error', error)
			}
		}

		const onGenerateInviteLink = async () => {
			isGenerateLink.value = true
			try {
				const response = await generateInviteUserLink(route.params.shopId as string, submitData.roleId as string, submitData.isSystemAdmin)
				inviteLink.value = `${baseCore}login?inviteToken=${response.token}`	
				onCopyLink()
			} catch (error) {
				//
			} finally {
				isGenerateLink.value = false
			}
		}

		onMounted(() => {
			//
			fetchUserInShopList()
			getUserRoles().then(({ data: allRoles }) => {
				roles.value = allRoles
				// set Default selected Roles
				submitData.roleId = allRoles.find((role: any) => role.name === Roles.ADMIN)._id
			})
		})

		return {
			search,
			roles,
			handleSearchEmployee,
			handleSelectEmployee,
			submitInvite,
			onRemoveUser,
			onDeleteUserFromList,
			submitData,
			userAddList,
			userInShopList,
			rolesMap,
			Roles,
			isGenerateLink,
			isShowDialogInviteUser,
			inviteLink,
			onGenerateInviteLink,
			onCopyLink
		}
	}
})
