
import { ElMessageBox, ElNotification } from 'element-plus'
import { defineComponent, onMounted, ref } from 'vue'
import { adjustShopRate, getShopRate } from '@/api/shop/shop.api'
import { useRoute } from 'vue-router'
import { getShopInbox, deleteShopInbox } from '@/api/shop/shop.api'

export default defineComponent({
	name: 'ShopRate',
	setup() {
		//
		const isLoading = ref(false)
		const inboxList = ref(null)
		const route = useRoute()

		const fetchInbox = () => {
			const shopId = route.params.shopId as string
			getShopInbox(shopId)
				.then(({ data: response }) => {
					inboxList.value = response
				})
		}

		const onDeleteInbox = async (inboxId: string) => {
			await deleteShopInbox(inboxId)
			fetchInbox()
		}

		onMounted(() => {
			fetchInbox()
		})

		return {
			isLoading,
			inboxList,
			route,
			onDeleteInbox
		}
	}
})
