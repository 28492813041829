import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68346c8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "ml-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "ตรวจสอบข้อมูลยืนยันตัวตน",
    "destroy-on-close": "",
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', false))),
    "model-value": _ctx.modelValue,
    width: "70%"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          (_ctx.bookingImage)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                span: 24,
                class: "flex justify-center mb-8"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_image, {
                    class: "preview-image",
                    "preview-src-list": _ctx.bookingImage,
                    src: _ctx.bookingImage
                  }, null, 8, ["preview-src-list", "src"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "confirmForm",
                "label-position": "top",
                model: _ctx.addBankForm,
                onSubmit: _withModifiers(_ctx.onSubmitBank, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: "ชื่อบัญชี",
                    prop: "accountName",
                    rules: [
							{ required: true, message: 'กรุณาระบุผู้ใช้ชื่อบีญชี', trigger: ['change', 'blur'] }
						]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.addBankForm.accountName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addBankForm.accountName) = $event)),
                        placeholder: "e.g. Nobita Posorthong"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "หมายเลขบัญชี (ถ้าเลขบัญชีมี - กรุณาใส่ - ด้วย)",
                    prop: "accountNumber",
                    rules: [
							{ required: true, message: 'กรุณาระบุหมายเลขบัญชี', trigger: ['change', 'blur'] }
						]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.addBankForm.accountNumber,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addBankForm.accountNumber) = $event)),
                        placeholder: "e.g 12345678910"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "ธนาคารผู้ให้บริการ",
                    prop: "accountIssuerId",
                    rules: [
							{ required: true, message: 'กรุณาเลือกธนาคารผู้ให้บริการ', trigger: ['change', 'blur'] }
						]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        class: "w-100",
                        modelValue: _ctx.addBankForm.accountIssuerId,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addBankForm.accountIssuerId) = $event)),
                        placeholder: "เลือกธนาคาร"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bankIssuerList, (bank) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: bank._id,
                              label: bank.name,
                              value: bank._id
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_1, [
                                  _createVNode(_component_el_image, {
                                    fit: "contain",
                                    class: "bank-image",
                                    src: bank.image,
                                    alt: bank.name
                                  }, null, 8, ["src", "alt"]),
                                  _createElementVNode("div", _hoisted_2, _toDisplayString(bank.name), 1)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { class: "mt-8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        "native-type": "submit",
                        type: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("ตกลง")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "onSubmit"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}