
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { formatDefault } from '@/utils/dayjs'
export interface AddressI {
    _id:             string;
    deleted:         boolean;
    deletedAt:       null;
    desc1:           string;
    provinceCode:    number;
    provinceName:    string;
    districtCode:    number;
    districtName:    string;
    subDistrictCode: number;
    subDistrictName: string;
    zipCode:         number;
    isSelected:      boolean;
    createdAt:       Date;
    updatedAt:       Date;
}

export default defineComponent({
	name: 'ShopAddress',
	setup() {
		const store = useStore()
		const shopDetail = computed(() => store.state.shop.shopDetail)
		const concatAddress = (address: AddressI) => {
			return `${address.desc1} ${address.subDistrictName} ${address.districtName} ${address.provinceName} ${address.zipCode}`
		}
		return {
			shopDetail,
			concatAddress,
			formatDefault
		}
	}
})
