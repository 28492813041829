
import { KycStatus, StopSentStatus } from '@/api/shop/shop.type'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
	name: 'ShopKycTag',
	props: {
		status: {
			type: String as PropType<KycStatus | StopSentStatus>,
			default: KycStatus.WAITING
		}
	},
	setup(props) {
		const tagData = computed(() => {
			switch(props.status) {
			case KycStatus.WAITING:
				return {
					label: 'ไม่ได้ส่งข้อมูลยืนยัน',
					type: 'info'
				}
			case KycStatus.IN_REVIEW:
				return {
					label: 'รอการตรวจสอบ',
					type: 'warning'
				}
			case KycStatus.REJECT:
				return {
					label: 'ตีกลับ',
					type: 'danger'
				}
			default:
				return {
					label: 'ยืนยันแล้ว',
					type: 'success'
				}
			}
		})

		return {
			tagData
		}
	}
})
