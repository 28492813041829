export const useReplaceInput = () => {
	/* white list */
	const stringReg = /[^\u0E00-\u0E7Fa-zA-Z\s]/g
	const stringNumberReg = /[^\u0E00-\u0E7Fa-zA-Z0-9\s]/g
	const numberReg = /[^0-9]/g
	const decimalReg = /[^0-9.]|\.(?=.*\.)/g
	const emojiWlReg = /[^\uD800-\uDBFF\u2702-\u27B0\uF680-\uF6C0\u24C2-\uF251\s]/g
	const stringSpecialReg = /[^\u0E00-\u0E7Fa-zA-Z0-9-!@#$%^&*()_+|~=`{}[\]:";'<>?,\s“./\\s]/g
	/* white list */
	/* back list */
	const emojiBlReg = /[\uD800-\uDBFF\u2702-\u27B0\uF680-\uF6C0\u24C2-\uF251\s]/g
	/* back list */

	/* white list */
	const string = (value: string): string => {
		return value.replace(stringReg, '')
	}
	const stringNumber = (value: string) => {
		return value.replace(stringNumberReg, '')
	}
	const number = (value: string): string => {
		return value.replace(numberReg, '')
	}
	const decimal = (value: string): string => {
		return value.replace(decimalReg, '')
	}
	const emojiWl = (value: string): string => {
		return value.replace(emojiWlReg, '')
	}
	const stringSpecial = (value: string): string => {
		return value.replace(stringSpecialReg, '')
	}
	/* white list */
    
	/* back list */
	const emojiBl = (value: string): string => {
		return value.replace(emojiBlReg, '')
	}
	/* back list */

	const paymentFieldConvert = (value: string): string => {
		const parseValue = value.replace(decimalReg, '')
		let newValue = parseFloat(parseValue)
		const isVaule = newValue === null || isNaN(newValue)
		if (isVaule) {
			newValue = 0
		}
		return newValue.toFixed(2)
	}

	const decimalValueChecker = (event: any) => {
		const isAllow: boolean = /[0-9.]/.test(String.fromCharCode(event.which))
		if (!isAllow) {
			event.preventDefault()
		}
	}

	const decimalNumberValueChecker = (event: any) => {
		const isAllow: boolean = /[0-9]/.test(String.fromCharCode(event.which))
		if (!isAllow) {
			event.preventDefault()
		}
	}
	const onPasteTextNumber = (event: any) => {
		event.preventDefault()
		let input = event.clipboardData.getData('text')
		input = input.replace(/[^0-9.]/g, '')
		return input
	}

	const decimalValueCheckerNotIncludeDot = (event: any) => {
		const isAllow: boolean = /[0-9]/.test(String.fromCharCode(event.which))
		if (!isAllow) {
		  event.preventDefault()
		}
	  }

	return {
		string,
		stringNumber,
		number,
		decimal,
		emojiWl,
		emojiBl,
		paymentFieldConvert,
		decimalValueChecker,
		stringSpecial,
		decimalNumberValueChecker,
		onPasteTextNumber,
		decimalValueCheckerNotIncludeDot
	}
}