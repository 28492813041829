export enum Roles {
    OWNER = 'OWNER',
	MANAGER = 'MANAGER',
    ADMIN = 'ADMIN',
}

export const rolesMap = {
	[Roles.OWNER]: {
		text: 'เจ้าของร้าน',
		type: ''
	},
	[Roles.MANAGER]: {
		text: 'ผู้ดูแล',
		type: 'success'
	},
	[Roles.ADMIN]: {
		text: 'แอดมิน',
		type: 'info'
	},
}
