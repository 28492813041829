export interface ICustomerDetail {
    firstName: string | undefined;
    lastName: string | undefined;
    companyEvidencePath: string;
    bookBankPath: string;
    idCardPath: string;
    companyEvidenceFile: any;
    bookBankFile: any;
    idCardFile: any;
}

export enum SHOP_CREDIT_TYPE {
    TOP_UP = 'TOP_UP',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    HALF_MONTH = 'HALF_MONTH',
    MONTHLY = 'MONTHLY',
    CHANNEL = 'CHANNEL',
}

export enum SHOP_TYPE {
    SELLER = 'SELLER',
    CHANNEL = 'CHANNEL',
    DEALER = 'DEALER',
  }