
import { defineComponent, onMounted, PropType, ref } from 'vue'
import { getBankList, BankDetail } from '@/api/bank/bank.api'
import { updateKyc } from '@/api/shop/shop.api'
import { useRoute } from 'vue-router'
import { KycStatus } from '@/api/shop/shop.type'
import { ElNotification } from 'element-plus'

export default defineComponent({
	name: 'ShopAddBankModal',
	props: {
		modelValue: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		bookingImage: {
			type: String,
			default: ''
		},
		kycDetail: {
			type: Object,
			default: null
		}
	},
	emits: ['update:modelValue'],
	setup (props, { emit }) {
		const addBankForm = ref({
			accountName: '',
			accountNumber: '',
			accountIssuerId: ''
		})
		const bankIssuerList = ref<BankDetail[]>([])
		const route = useRoute()
		const confirmForm = ref()

		onMounted(() => {
			fetchBankList()
		})

		const fetchBankList = () => {
			getBankList().then(response => {
				bankIssuerList.value = response.data
			})
		}

		const onSubmitBank = async () => {
			try {
				const isValidate = await confirmForm.value.validate()
				if(!isValidate) return
				const payload = {
					status: KycStatus.APPROVE,
					bank: {
						accountName: addBankForm.value.accountName,
						accountNumber: addBankForm.value.accountNumber,
						accountIssuerId: addBankForm.value.accountIssuerId
					}
				}
				const res = await updateKyc(route.params.shopId as string, payload)
				if(res.data.updateStatus === 'SUCCESSFUL') {
					emit('update:modelValue', false)
					ElNotification({
						title: 'สำเร็จ',
						message: 'ยืนยันสถานะสำเร็จ',
						type: 'success'
					})
				}else {
					ElNotification({
						title: 'เกิดข้อผิดพลาด',
						message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
						type: 'error'
					})
				}
			} catch (_) {
				ElNotification({
					title: 'เกิดข้อผิดพลาด',
					message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
					type: 'error'
				})
			}
		}

		

		onMounted(() => {
			addBankForm.value.accountIssuerId = props.kycDetail?.bank?.accountIssuer
			addBankForm.value.accountName = props.kycDetail?.bank?.accountName
			addBankForm.value.accountNumber = props.kycDetail?.bank?.accountNumber
		})

		return {
			addBankForm,
			bankIssuerList,
			confirmForm,
			fetchBankList,
			onSubmitBank,
		}
	}
})
